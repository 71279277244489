import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Header from '../Header/Header';
import MainScreen from '../MainScreen/MainScreen';
import { useEffect,useState } from 'react';


const LoginSSOToken = React.memo( () => {
  const { instance, accounts } = useMsal();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // Check if MSAL instance and accounts are available
    if (instance && accounts) {
      setIsInitialized(true);
    }
  }, [instance, accounts]);

  useEffect(() => {
    const MAX_RETRIES = 5; // Maximum number of retry attempts
    const RETRY_DELAY = 100; // Delay between retries in milliseconds

    const loginIfNotLoggedIn = async (retryCount = 0) => {
      try {
        if (instance && accounts.length === 0) {
          showLoading(); 
          await instance.loginPopup(); // Initiates a login popup
          hideLoading(); 
        }
      } catch (error) {
        hideLoading(); // Ensure loading spinner is hidden in case of error
        if (error.message && error.message.includes('You must call and await the initialize function before attempting to call any other MSAL API')) {
          if (retryCount < MAX_RETRIES ) {
            setTimeout(() => {
              loginIfNotLoggedIn(retryCount + 1); // Retry login with incremented retry count
            }, RETRY_DELAY);
          } else {
            console.error('Max retry attempts reached. Login failed.');
          }
        }
      }
    };

    if (isInitialized) {
      loginIfNotLoggedIn(); // Start the login process
    }

  }, [isInitialized, instance, accounts]); // Trigger login if initialized and accounts are empty

  // Function to show loading animation
  function showLoading() {
    var loadingContainer = document.getElementById('loadingContainer');
    if (loadingContainer && loadingContainer.style) {
      loadingContainer.style.display = 'flex';
    }
  }

  // Function to hide loading animation
  function hideLoading() {
    var loadingContainer = document.getElementById('loadingContainer');
    if (loadingContainer && loadingContainer.style) {
      loadingContainer.style.display = 'none';
    }
  }

  const closePopup = () => {
    document.getElementById('modalPopup')?.classList?.add('d-none');
    var elements = document.getElementsByClassName('popupContent');
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
    document.getElementById("popupContentCloseButton")?.classList?.add('d-none');
    var elements2 = document.getElementsByClassName('popupContent');
    while (elements2.length > 0) {
      elements2[0].parentNode.removeChild(elements2[0]);
    }
  }
  const handelLogout = () => {
    instance.logout();
  }

const accountInfo = {
  roles: accounts.length > 0 ? accounts[0].idTokenClaims.roles[0] : '',
  username: accounts.length > 0 ? accounts[0].username  : '',
  name: accounts.length > 0 ? accounts[0].name : ''
}
  return (
    <div className="App">
      {<AuthenticatedTemplate>
        <div>
          <Header onlogOut={handelLogout} account={accountInfo}/>
          <MainScreen />
          <div className='fullScreenBlur d-none' id="modalPopup">
            <div className="setBackgroundModalPopup">
              <div className="Modal-Popup-css">
                <span className="closeButton" onClick={closePopup}>&times;</span>
                <div id="popupContent" className="contentAreaApproval">

                </div>
                <button id="popupContentCloseButton" className='d-none buttonCloseOnPopup css-Button-Classes-infoPuop' onClick={closePopup}>OK</button>
              </div>
            </div>
          </div>
          <div className="loading-container" id="loadingContainer">
            <div className="loading">
              <div className="loading-circle"></div>
              <div className="loading-circle"></div>
              <div className="loading-circle"></div>
            </div>
          </div>
        </div>
      </AuthenticatedTemplate>}
      <UnauthenticatedTemplate>
          <div className="loading-container-Login">
            <div className="loading">
              <div className="loading-circle"></div>
              <div className="loading-circle"></div>
              <div className="loading-circle"></div>
            </div>
          </div>
      </UnauthenticatedTemplate>
    </div>
  );
});



export default LoginSSOToken;
