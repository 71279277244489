import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const FileViewerPopup = ({ open, onClose, content }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          height: '80%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <DialogContent
        style={{
          flex: 1,
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px', // Optional: Adjust padding as needed
          maxWidth: '100%',
        }}
      >
        <Typography
          component="pre"
          style={{
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word', // Ensure long words break and fit the container
            maxWidth: '100%',
            maxHeight: '100%',
            overflow: 'auto',
            display: 'block',
            wordBreak: 'break-word' // Ensure text breaks correctly
          }}
        >
          {content}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileViewerPopup;
