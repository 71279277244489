import { React, useState, useRef, useEffect } from 'react';
import './Header.css'; // You can style your header in a separate CSS file
import logo from '../Images/Logo.png';
import { Avatar, IconButton, Tooltip, Box } from '@mui/material';
import ProfilePopover from '../CommonComponent/ModelPopup/ProfilePopover';


// Helper function to generate a random color
const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const sanitizeText = (text) => {
    const element = document.createElement('div');
    element.innerText = text;
    return element.innerHTML;
};

const Header = ({ onlogOut, account }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const buttonRef = useRef(null);
    const [bgColor, setBgColor] = useState(getRandomColor());
    const handleClickOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getInitials = (name) => {
        if (!name) return '';
        const initials = name.split(' ').map(word => word[0]).join('');
        return initials.toUpperCase();
    };

    const open = Boolean(anchorEl);



    return (
        <div className="header">
            <img className='imageLogo' src={logo}></img>
            <div className='softwareName'>Vehicle Software Management System</div>
            <div className='user-profile-icon'>
                <Tooltip
                    title={
                        <Box >
                            <div>User: {sanitizeText(account.name)}</div>
                            <div>Role: {sanitizeText(account.roles)}</div>
                        </Box>
                    }
                    arrow
                    placement="left"  // Position the tooltip to the left
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 10],  // Adjust the offset to move tooltip closer or further away
                                },
                            },
                        ],
                    }}
                >
                    <IconButton
                        onClick={handleClickOpen}
                        ref={buttonRef}
                        size="large"
                    >
                        <Avatar
                            alt={sanitizeText(account.name)}
                            src={account.profilePicture}
                            sx={{
                                width: '2.47vw',
                                height: '5vh',
                                fontSize: '2.33vh',
                                bgcolor: account.profilePicture ? 'transparent' : bgColor,
                                color: account.profilePicture ? 'transparent' : 'white',
                                mr: 2
                            }}
                        >
                            {!account.profilePicture && getInitials(account.name)}
                        </Avatar>
                    </IconButton>
                </Tooltip>
                <ProfilePopover
                    anchorEl={buttonRef.current}
                    open={open}
                    onClose={handleClose}
                    user={account}
                    onLogOut={onlogOut}
                    bgColor={bgColor}
                />
            </div>
        </div>
    );
}

export default Header;
