
import axios from 'axios';
import {accessToken} from '../../../Hook/userRoles'; 
    const fetchDataAllBranches = async (instance, accounts) => {
      try {
        document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
        const response = await axios.get(process.env.REACT_APP_FETCH_ALL_BRANCHES, {
          headers: {
            Authorization: `Bearer ${await accessToken(instance, accounts)}`,
          },
        });
        if(response.status === 200){
          const repositories = response.data.value;
          const modifiedRepoData = repositories.map(repo => ({
            name: repo.name.replace("refs/heads/", ''),
            creatorDisplayName: repo.creator.displayName,
            isOpen: false,
            objectId: repo.objectId
          }));
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          
          return  modifiedRepoData;
        } else {
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          return {status:response.status}
        }
      } catch (error) {
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        return {error:error};
      }
    };

export default fetchDataAllBranches;
