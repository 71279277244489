import { React, useEffect, useState } from 'react';
import { Popover, Typography, Button, Divider, Box, Avatar } from '@mui/material';


const ProfilePopover = ({ anchorEl, open, onClose, user, onLogOut, bgColor }) => {

  const handleSignOut = () => {
    onLogOut(); // Handle user sign out
  };

  const handleClose = () => {
    onClose(); // Close the popover
  };

  const getInitials = (name) => {
    if (!name) return '';
    const initials = name.split(' ').map(word => word[0]).join('');
    return initials.toUpperCase();
  };
  useEffect(() => {
    setTimeout(() => {
      const popoverElement = document.getElementById('elementPopup');
      if (popoverElement) {
        const paperElement = popoverElement.querySelector('.MuiPaper-root');
        if (paperElement) {
          paperElement.style.left = ''; // Remove the 'left' property
        }
        if( window.innerHeight < 400){
          paperElement.style.top = ''; // Remove the 'left' property
        }
      }
    }, 30); // Adjust timeout as needed
  }, [open]); // Run when the popover opens or when `open` changes

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom', // Anchor to the bottom of the button
        horizontal: 'left', // Anchor to the left of the button
      }}
      transformOrigin={{
        vertical: 'top', // Popover opens from the top of the anchor element
        horizontal: 'right', // Aligns popover's right edge with the anchor's left edge
      }}
      PaperProps={{
        style: { width: '30.92vw', padding: '1.32vw',paddingTop: '0.32vw',paddingRight: '0.32vw', right: 10 }, // Adjust width as needed
      }}
      id="elementPopup"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1,
          px: 2,
          py: 1,
          borderBottom: '1px solid #e0e0e0',
        }}
      >
        <Typography variant="subtitle2" sx={{ flex: 1, textAlign: 'left', fontSize: '0.875rem' ,}}>
          Vehicle Software Management System
        </Typography>
        <Button onClick={handleSignOut} variant="outlined" sx={{
          fontSize: '0.75rem',
          borderColor: '#08645c', // Border color for outlined variant
          color: '#08645c', // Text color
          '&:hover': {
            borderColor: '#064d43', // Border color on hover
          },
        }}>
          Sign Out
        </Button>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center',  padding:'1.3vw'}}>
        <Avatar
          sx={{
            width: '3.99vw',
            height: '8vh',
            bgcolor: user.profilePicture ? 'transparent' : bgColor,
            color: user.profilePicture ? 'transparent' : 'white',
            mr: 2
          }}
          alt={user.name}
          src={user.profilePicture}
        >
          {!user.profilePicture && getInitials(user.name)}
        </Avatar>
        <Box >
          <Typography variant="h6" sx={{ fontSize: '0.875rem' }}>{user.name || 'N/A'}</Typography>
          <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.75rem' }}>
            {user.username || 'N/A'}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mb: 1 }} />
      <Typography variant="subtitle2" sx={{ mb: 1, fontSize: '0.75rem' , padding:'1.4vw' }}>
        User Role :- {user.roles?.length > 0 ? user.roles.charAt(0).toUpperCase() + user.roles.slice(1) + ' .' : 'No roles assigned .'}
      </Typography>
      {/* <Typography variant="body2" sx={{ mb: 2, fontSize: '0.75rem' }}>
        Username: {user.username || 'N/A'}
      </Typography> */}
      <Divider sx={{ mb: 1 }} />
      <Box fullWidth sx={{ paddingRight: '1vw',fontSize: '0.75rem', color: '#08645c', display:'flex',justifyContent:'right'}}>
        <Button onClick={handleClose} color="primary"  sx={{ fontSize: '0.75rem', color: '#08645c', width:'2vw',alignItems:'right'}}>
          Close
        </Button>
      </Box>
    </Popover>
  );
};

export default ProfilePopover;
