import React, { useState } from 'react';
import './App.css';
import LoginSSOToken from './Component/Login/Login'
function App() {
  return (
    <div id="AppContainer" className="App">
       <LoginSSOToken/>
    </div>
  );
}
export default App;
