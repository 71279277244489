import React, { useState } from 'react';
import axios from 'axios';
import ModalPopup from '../ModelPopup/ModalPopup';
import './CreateFolderForm.css';
import ErrorPopup from '../ModelPopup/ErrorPopup';
import { updatePipelineConfiguration } from './BuildDetails';
import { accessToken } from '../../../Hook/userRoles';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';


let newShaObject = "";
const CreateFolderForm = ({ onCloseButton, newBranch, branchName, folderLocation = null, approvalId = null, approvalStatus = null, folderPathRecived = null, msalInstance = null, msalAccounts = null }) => {
  let { instance, accounts } = useMsal();

  if (msalInstance && msalAccounts && accounts.length === 0) {
    instance = msalInstance;
    accounts = msalAccounts;
  }
  const [folderName, setFolderName] = useState('');

  const branch = window.branch;
  const createFolderpath = folderLocation ? folderLocation.split("/") : null;
  let folderPath = createFolderpath ? createFolderpath[0] : folderPathRecived;
  if (folderPath === undefined) {
    folderPath = `/${folderName}/.gitignore`;
    folderPath = folderPath.replace('[object HTMLInputElement]', '');
  } else if (folderPath) {
    folderPath = `${folderPath}/${folderName}/.gitignore`;
  } else {
    folderPath = `${folderName}/.gitignore`;
  }
  folderPath = folderPath.replace('[object HTMLInputElement]', '');
  const dataForDescription = folderLocation ? folderLocation : folderPath;
  const [commitMessage, setCommitMessage] = useState(() => {
    if (branchName && dataForDescription) {
      if (!dataForDescription) {
        return branchName;
      } else {
        const processedDescription = dataForDescription.replaceAll('/', '_').replace('.gitignore', '');
        const result = ` ${branchName}_${processedDescription}`;
        // Check if the part after the underscore is empty or only whitespace
        const underscoreIndex = result.lastIndexOf('_');
        if (underscoreIndex !== -1) {
          const afterUnderscore = result.substring(underscoreIndex + 1);
          if (/^\s*$/.test(afterUnderscore)) {
            // Remove the underscore at the end
            return result.substring(0, underscoreIndex);
          }
        }
        return result;
      }
    } else {
      return `Create New ${newBranch === 'Branch' ? 'Program' : newBranch.toLowerCase()}`;
    }
  });



  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
  const [message, setMessage] = useState('');

  const closeErrorPopup = () => {
    setIsErrorPopupOpen(false);
    onCloseButton();
  }
  const handleClose = () => {
    // Your handleClose logic
    onCloseButton();
  }

  const handleFolderNameChange = (event) => {
    const newValue = event.target.value;
    setFolderName(newValue);
    if (newBranch !== 'Branch') {
      setCommitMessage(prevCommitMessage => {
        const parts = prevCommitMessage.split('_'); // Split into parts based on underscore

        // Remove and retrieve the last part (suffix)
        const suffix = parts.pop();

        // Concatenate the remaining parts with the new value
        const updatedMessage = parts.join('_') + '_' + newValue;

        return updatedMessage;
      });
    }

  };








  const handleCommitMessageChange = (event) => {
    setCommitMessage(event.target.value);
  }
  // Function to show loading animation
  function showLoading() {
    var loadingContainer = document.getElementById('loadingContainer');
    if (loadingContainer && loadingContainer.style) {
      loadingContainer.style.display = 'flex';
    }
  }

  // Function to hide loading animation
  function hideLoading() {
    var loadingContainer = document.getElementById('loadingContainer');
    if (loadingContainer && loadingContainer.style) {
      loadingContainer.style.display = 'none';
    }
  }


  const pullLatest = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_FETCH_ALL_BRANCHES, {
        headers: {
          Authorization: `Bearer ${await accessToken(instance, accounts)}`,
        },
      });

      if (response.status === 200) {
        const repositories = response.data.value;
        const modifiedRepoData = repositories.map(repo => ({
          name: repo.name.replace("refs/heads/", ''),
          creatorDisplayName: repo.creator.displayName,
          isOpen: false,
          objectId: repo.objectId
        }));

        window.objectId = modifiedRepoData.find(data => data.name === branchName)?.objectId;
      } else {
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        setMessage(`There was an error while trying to add the new ${newBranch.toLowerCase()}`);
        setIsErrorPopupOpen(true);
        return;
      }

    } catch (error) {
      hideLoading();
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      console.error('Error pulling latest changes:', error.response ? error.response.data : error.message);
    }
  };

  const createFolder = async () => {
    if (folderName.trim() !== '') {
      const validationReleasePrefix = 'Validation Release';
     if (!/^ValidationRelease\d+\.\d+[A-Za-z0-9]*$/.test(folderName.trim()) && (folderLocation || folderName.startsWith(validationReleasePrefix))) {
        setMessage('Error in software approval, The folder name should start with "ValidationRelease" followed by a version number (e.g., ValidationRelease1.2) and not have any extra spaces or words.');
        setIsErrorPopupOpen(true);
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        return;
      } else if (newBranch === 'Folder' && (branch === null || branch === '')) {
        setMessage(`Please select specific platform to create new folder.`);
        setIsErrorPopupOpen(true);
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        return;
      } else if (commitMessage === null || commitMessage === '') {
        setCommitMessage(folderPath.replaceAll('/', '_'));
      }
      await pullLatest();

      showLoading();
      try {
        document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');

        if (newBranch === "Branch") {
          const response = await axios.get(`https://dev.azure.com/${process.env.REACT_APP_AUTH_CLIENT_ID}/${process.env.REACT_APP_PUBLIC_KEY}/_apis/git/repositories/${process.env.REACT_APP_REPOSITORY}/refs?filter=heads/main&api-version=6.0`, {
            headers: {
              Authorization: `Bearer ${await accessToken(instance, accounts)}`,
              'Content-Type': 'application/json'
            }
          });
          if (response.status === 200) {
            const objectIdObject = response.data.value.map((data) => { return data.objectId });
            newShaObject = objectIdObject[0];
          } else {
            document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
            setMessage(`There was an error while trying to add the new ${newBranch.toLowerCase()}`);
            setIsErrorPopupOpen(true);
            hideLoading();
            return;
          }
        }


        // Prepare the basic change object
        const basicChange = {
          changeType: "add",
          newContent: {
            content: "",
            contentType: "rawText"
          }
        };

        // Create an array to store all changes
        const allChanges = [];

        // Add the initial change for the folderPath or /.gitignore based on newBranch value
        const initialChange = {
          ...basicChange,
          item: {
            path: newBranch === "Branch" ? '/.gitignore' : folderPath
          }
        };
        allChanges.push(initialChange);

        const lowerFolderName = folderName.toLowerCase();

        // Regex to match "PF" pattern with version number
        const pfRegex = /^pf(\d+\.\d+)$/i;

        // Regex to extract version number from "platform" pattern
        const platformRegex = /platform(\d+\.\d+)/i;

        // Check if the folderName matches "PF" pattern
        const isPfMatch = pfRegex.test(lowerFolderName);

        // Check if the folderName contains "platform" and extract version
        const platformMatch = folderName.match(platformRegex);
        const platformVersion = platformMatch ? platformMatch[1] : null;

        // Extract version from "PF" pattern
        const pfMatch = lowerFolderName.match(pfRegex);
        const pfVersion = pfMatch ? pfMatch[1] : null;

        // Determine the version to use
        const versionToUse = pfVersion || platformVersion;

        // If folderName matches either pattern or contains "platform" with version
        if ((isPfMatch || platformVersion) && newBranch !== "Branch") {
          // Define the paths to be updated
          const additionalChanges = [
            '/Submission/Archive/.gitignore',
            '/ValidationRelease2.1/.gitignore',
            '/Prerelease/.gitignore',
            '/BusinessRelease/.gitignore',
            '/BusinessReleaseDocs/.gitignore'
          ].map(path => ({
            ...basicChange,
            item: {
              path: `${folderPath.replace('/.gitignore', path.replace(/(\d+\.\d+)/g, versionToUse))}`
            }
          }));

          // Add the new changes to the allChanges array
          allChanges.push(...additionalChanges);
        }


        // Create the commit object
        const commit = {
          comment: commitMessage,
          changes: allChanges
        };

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}pushes?api-version=4.1`, {
          refUpdates: [
            {
              name: `refs/heads/${newBranch === "Branch" ? folderName : branch}`,
              oldObjectId: newBranch === "Branch" ? newShaObject : window.objectId,
            }
          ],
          commits: [commit],
        }, {
          headers: {
            Authorization: `Bearer ${await accessToken(instance, accounts)}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.status === 200 || response.status === 201) {
          newShaObject = "";
          setMessage(`New ${newBranch === 'Branch' ? 'program' : newBranch.toLowerCase()} created successfully`, folderName);
          setIsErrorPopupOpen(true);
          if (folderLocation) {
            const responceData = await updatePipelineConfiguration(folderLocation, folderPath.replace('/.gitignore', ""), approvalId, approvalStatus, branchName, null, null, instance, accounts);
            if (responceData.showMessage === true) {
              setMessage(responceData.message);
              if (!isErrorPopupOpen) {
                setIsErrorPopupOpen(true); // Toggle the state
              }
            }
          }
          hideLoading();
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        } else {
          setMessage(`There was an error while trying to add the new ${newBranch.toLowerCase()}`);
          setIsErrorPopupOpen(true);
          hideLoading();
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          return;
        }

      } catch (error) {
        newShaObject = "";
        setMessage(`There was an error while trying to add the new ${newBranch.toLowerCase()}`);
        setIsErrorPopupOpen(true);
        hideLoading();
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        return;
      }
    } else {
      hideLoading();
      setMessage(`${newBranch === 'Branch' ? 'program' : newBranch.toLowerCase()} name cannot be empty.`, folderName.toLowerCase());
      setIsErrorPopupOpen(true);
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return;
    }
  };


  return (

    <ModalPopup onClose={handleClose}>
      <div className='container-createFolder'>
        <div className='lableTextForCreateBranch'>
          {newBranch === "Branch" ? "Create New Program" : `Create New ${newBranch} `}
        </div>
        {newBranch !== "Branch" && <div className="note">
          Note :-  If the new folder name includes the word ‘Platform’ Or 'PF', 5 sub folders ( Submission, Validation Release, Business Release, Pre-release, Business Release Docs ) will be created automatically.
        </div>}

        <div className='input-container'>
          <label htmlFor="folderName" className='lebel-width' >{newBranch === "Branch" ? "Program" : `${newBranch} `} Name:</label>
          <input
            type="text"
            id="folderName"
            placeholder={newBranch === "Branch" ? "Enter Program Name" : `Enter ${newBranch} Name`}
            disabled={isErrorPopupOpen}
            value={folderName}
            onChange={handleFolderNameChange}
          />
        </div>
        <div className='input-container'>
          <label htmlFor="commitMessage" className='lebel-width'>Description:</label>
          <input type="text" id="commitMessage" placeholder="Enter Description" disabled={isErrorPopupOpen} value={commitMessage} onChange={handleCommitMessageChange} />
        </div>
        <button className='css-Button-Classes' disabled={isErrorPopupOpen} onClick={createFolder}>Create {newBranch === "Branch" ? "Program" : `${newBranch}`}</button>
        {isErrorPopupOpen && (
          <ErrorPopup message={message} onClose={closeErrorPopup} />
        )}
      </div>
    </ModalPopup>

  );
};

export default CreateFolderForm;
