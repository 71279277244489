import React, { useState } from 'react';
import "./ErrorPopup.css";

const ModalPopup = ({ onClose, children ,onOperationCancel=null}) => {
  const [isOpen, setIsOpen] = useState(true);

  const closePopup = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    isOpen && (
      <div className='fullScreenBlur'>
        <div className="setBackgroundModalPopup">
          <div className="Modal-Popup-css">
            <span className="closeButton" onClick={closePopup}>&times;</span>
            <div className={`${onOperationCancel !== null? 'informationPopup':'contentArea'}`}>
              {onOperationCancel === null && children}
              {onOperationCancel !== null && <div>{children}</div>}           
              {onOperationCancel !== null && <div className='ButtonConatinerOncheckList'>
              <button className='css-Button-Classes-infoPuop' onClick={onOperationCancel} >OK</button>
              <button className='css-Button-Classes-infoPuop' onClick={closePopup}>Cancel</button>
              </div>}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ModalPopup;
