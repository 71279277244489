import React, { useState, useEffect } from 'react';

const CheckBoxComponent = ({onokButtonClick, oncancelButtonClick}) => {
  const [checkedCount, setCheckedCount] = useState(0);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    setAllChecked(checkedCount === 3);
  }, [checkedCount]);

  const handleCheckBoxChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedCount(checkedCount + 1);
    } else {
      setCheckedCount(checkedCount - 1);
    }
  };

  const handleOkButtonClick = () => {
    // Your logic for the OK button click
    onokButtonClick();
  };

  const handleCancelButtonClick = () => {
    oncancelButtonClick();
  }

  return (
    <div className="Modal-Popup-css">
        
      <div className='checkListheading'>Before submitting the software, please review the following checklist.</div>
      <br />
      <div className='checkboxContainer'>
        <label className='checkboxContainerlabel'>
          <input type="checkbox" className='checkboxContainercheckbox' onChange={handleCheckBoxChange} /> Part Number.
        </label>
        <br />
        <label className='checkboxContainerlabel'>
          <input type="checkbox" className='checkboxContainercheckbox' onChange={handleCheckBoxChange} /> DTC list.
        </label>
        <br />
        <label className='checkboxContainerlabel'>
          <input className='checkboxContainercheckbox' type="checkbox" onChange={handleCheckBoxChange} /> Release notes
        </label>
      </div>
      <br />
      <div className='ButtonConatinerOncheckList'>
        <button className='css-Button-Classes-infoPuop' onClick={handleOkButtonClick} disabled={!allChecked}>
            OK
        </button>
        <button className='css-Button-Classes-infoPuop' onClick={handleCancelButtonClick} >
            Cancel
        </button>
      </div>

    </div>
  );
};

export default CheckBoxComponent;
