import axios from 'axios';
import JSZip from 'jszip';
import {accessToken} from '../../../Hook/userRoles'

// Function to show loading animation
function showLoading() {
  var loadingContainer = document.getElementById('loadingContainer');
  if (loadingContainer && loadingContainer.style) {
    loadingContainer.style.display = 'flex';
  }
}

// Function to hide loading animation
function hideLoading() {
  var loadingContainer = document.getElementById('loadingContainer');
  if (loadingContainer && loadingContainer.style) {
    loadingContainer.style.display = 'none';
  }
}


export const singleFileDownload = async (branchName, filePath,instance, accounts) => {
  try {
    document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}items?versionDescriptor.version=${branchName}&download=true&scopePath=${filePath}&$format=zip&resolveLfs=true&api-version=5.0`,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${await accessToken(instance, accounts)}`,
        }
      }
    );
    const blob = response.data;
    if (response.status !== 200){
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return {showMessage: true,message:`There's an error downloading the data. Please refresh the page and try again.`};
    }
    const zip = await JSZip.loadAsync(blob);
    const fileExtension = filePath.split('.').pop().toLowerCase();

    // Define the condition to check for text-based file formats
    const isTextFile = (
        fileExtension === 'txt' ||
        fileExtension === 'md' ||
        fileExtension === 'log' ||
        fileExtension === 'js' ||
        fileExtension === 'css' ||
        fileExtension === 'html' ||
        fileExtension === 'xml' ||
        fileExtension === 'json' ||
        fileExtension === 'yaml' ||
        fileExtension === 'yml' ||
        fileExtension === 'csv' ||
        fileExtension === 'ini' ||
        fileExtension === 'cfg' ||
        fileExtension === 'config' ||
        fileExtension === 'rtf' ||
        fileExtension === 'htm' ||
        fileExtension === 'py' ||
        fileExtension === 'java' ||
        fileExtension === 'cpp' ||
        fileExtension === 'c' ||
        fileExtension === 'bat' ||
        fileExtension === 'sh' ||
        fileExtension === 'svg'
    );

    if(isTextFile){
        const file = zip.file(filePath.split('/').pop());
        if (!file) {
            document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
            return {showMessage: true,message:`There's an error downloading the data. Please refresh the page and try again.`};
        }
        const content = await file.async('text');
        return content;
    } else if (fileExtension === 'pdf'){
      const file = zip.file(filePath.split('/').pop());

      if (!file) {
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        return {showMessage: true,message:`There's an error downloading the data. Please refresh the page and try again.`};
      }

      // Read the file content as array buffer
      const arrayBuffer = await file.async('arraybuffer');

      // Convert array buffer to Blob
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

      // Generate a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Return the URL
      return url;
    } else if (fileExtension === 'tssssss'){

        const file = zip.file(filePath.split('/').pop());

        if (!file) {
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          return {showMessage: true,message:`There's an error downloading the data. Please refresh the page and try again.`};
        }
        
        // Read the file content as array buffer
        const arrayBuffer = await file.async('arraybuffer');
        
        // Determine the MIME type of the file (assuming it's an Excel file)
        const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; // Adjust as needed for different Excel formats
        
        // Convert array buffer to Blob
        const blob = new Blob([arrayBuffer], { type: mimeType });
        
        // Generate a URL for the Blob
        const url = URL.createObjectURL(blob);
          
          // Open the URL in a new tab
          const googleDocsUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(url)}&embedded=true`;

          // Open the Google Docs Viewer URL in a new tab
          const newWindow = window.open(googleDocsUrl, '_blank');
          
          // Check if the new window was successfully opened
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
              // Pop-up was blocked or failed to open
              console.error('Unable to open new tab. Please check your browser settings.');
          }

    }else {
      try {
        const directoryHandle = await window.showDirectoryPicker();
        for await (const [relativePath, file] of Object.entries(zip.files)) {
          if (!file.dir) {  // ignore directories
            const content = await file.async('blob');  // or 'text', 'arraybuffer', 'uint8array', 'nodebuffer' as per your need
            const filename = relativePath.split('/').pop();
            const fileHandle = await directoryHandle.getFileHandle(filename, { create: true });
            const writableStream = await fileHandle.createWritable();
            await writableStream.write(content);
            await writableStream.close();
            return {showMessage: true,message:`Software downloaded successfully!`};
          }
        }
      } catch (error) {
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        if (error.name === 'AbortError') {
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          return {showMessage: true,message:`The directory selection was canceled. Please try downloading again.`};
        } else {
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          return {showMessage: true,message:`There's an error downloading the data. Please refresh the page and try again.`};
        }
      }
    }

    document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
  } catch (error) {
    document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
    return {showMessage: true,message:`There's an error downloading the data. Please refresh the page and try again.`};
  }
}


export const downloadDataForSingleFileFolder = async (branchName,filePath,instance, accounts,returnBlob = null) => {
 
  try {
    document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}items?versionDescriptor.version=${branchName}&download=true&scopePath=${filePath}&$format=zip&resolveLfs=true&api-version=5.0`,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${await accessToken(instance, accounts)}`,
        }
      }
    );
      if(returnBlob === 'returnBlob'){
        const blob = response.data;

        // Create a JSZip instance and load the blob data
        const zip = new JSZip();
        const zipContent = await zip.loadAsync(blob);
    
        // Optionally: List files in the ZIP to verify content
        zipContent.forEach((relativePath, zipEntry) => {
          // if (!zipEntry.dir) {
          //   console.log(`Found file in zip: ${relativePath}`);
          // }
        });
    
        // Now proceed to create a new zip and push it
        // await createZipFromBlobAndPush(zipContent, 'main', 'Update with new blob', instance, accounts, 'folderPath');
    
        return {showMessage: false,message:``,blob:zipContent};
      }
         if (response.status !== 200){
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          return {showMessage: true,message:`There's an error downloading the data. Please refresh the page and try again.`};
         }
        try {
          const directoryHandle = await window.showDirectoryPicker();
          const blob = await new Blob([response.data]);
          const url = await window.URL.createObjectURL(blob);
          const packageName = filePath.split('/');
          let downloadArtifactName = [
              branchName,
              ...packageName.filter(Boolean).map((pkg, index) => `${'_'}${pkg}`)
            ].join('');
          const fileHandle = await directoryHandle.getFileHandle(downloadArtifactName+'.zip', { create: true });
          const writableStream = await fileHandle.createWritable();
          await writableStream.write(blob);
          await writableStream.close();
          window.URL.revokeObjectURL(url);
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          return {showMessage: true,message:`Software downloaded successfully!`};
      } catch (err) {
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        return {showMessage: true,message:`The directory selection was canceled. Please try downloading again.`};
      }
  } catch (error) {
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return {showMessage: true,message:`The directory selection was canceled. Please try downloading again.`};
  }
};

export async function DownloadContent(filePath, branchName,instance, accounts) {
  showLoading();
  document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
   if(filePath.includes('Submission')){
    return await downloadDataForSingleFileFolder(branchName,filePath,instance, accounts);
    
    } else if (filePath.includes('Prerelease')||(filePath.includes('BusinessRelease') && !filePath.includes('BusinessReleaseDocs'))||filePath.includes('Validation')){
      let pipelineID ;
      if(filePath.includes('Validation')){
        pipelineID = process.env.REACT_APP_ValidationPipeline;
      } else if (filePath.includes('Prerelease')) {
        pipelineID = process.env.REACT_APP_PreReleasePipeline;
      } else {
        pipelineID = process.env.REACT_APP_ReleasePipeline;
      }


        if ((filePath.split('/').pop().includes('Validation') ||
            filePath.split('/').pop().includes('Prerelease') ||
            filePath.split('/').pop().includes('BusinessRelease'))||(filePath.split('/').pop().includes('Archive') && filePath.includes('Validation') ||
            filePath.split('/').pop().includes('Archive') && filePath.includes('Prerelease') )) {
            try{
              const directoryHandle = await window.showDirectoryPicker();
              const blobs = [];
  
              // Assuming window.buildIDArray contains objects with an 'id' property
              for (let i = 0; i < window.buildIDArray.length; i++) {
                  const id = window.buildIDArray[i];
                  const allBranchBuild = await getArtifactFromBuild(id, instance, accounts);
                  const data = await downloadUrlData(allBranchBuild, null, branchName, filePath, 'multiple', instance, accounts);
                  blobs.push(data);
              }
             
              const packageName = filePath.split('/');
              let downloadArtifactName = [
                  branchName,
                  ...packageName.filter(Boolean).map((pkg, index) => `${'_'}${pkg}`)
                ].join('');
              const zips = new JSZip();
  
              // Function to extract contents from each blob (assumed to be a zip file)
              async function extractContents(blob, index) {
                  try {
                      const nestedZip = await JSZip.loadAsync(blob); // Load the nested zip file
                      nestedZip.forEach((relativePath, file) => {
                          if (!file.dir) { // Check if it's a file (not a directory)
                              zips.file(relativePath, file.async('blob')); // Add file to the main zip with its original name and content as-is
                          }
                      });
                  } catch (error) {
                      console.error('Error extracting contents from blob:', error);
                      throw error; // Propagate the error to handle it further
                  }
              }
  
              // Extract contents from each blob and add to the main zip
              for (let i = 0; i < blobs.length; i++) {
                  const blob = blobs[i];
                  await extractContents(blob, i);
              }
  
              // Generate the zip content
              const zipBlob = await zips.generateAsync({ type: 'blob' });
  
              // Create a single zip file and write its content
              const filename = downloadArtifactName + '.zip';
              const fileHandle = await directoryHandle.getFileHandle(filename, { create: true });
              const writableStream = await fileHandle.createWritable();
              await writableStream.write(zipBlob);
              await writableStream.close();
  
              // Optionally, perform additional actions after successful download
              document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
              return {showMessage:true,message:'Software downloaded successfully!'};
            } catch {
              document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
              return {showMessage:true,message:'The directory selection was canceled. Please try downloading again.'};
            }
        } else {
          const dataPath = filePath.includes('/Archive')?filePath.replace('/Archive',''):filePath;
          const segments = dataPath.split('/');
          const firstThreeSegments = segments.slice(0, 3).join('/');
          const dataWeGot =  await getBuildsForPipeline(pipelineID,instance, accounts,firstThreeSegments,branchName);
          if (dataWeGot.showMessage === true){
            return dataWeGot;
           }
          if (dataWeGot.length > 0){
            dataWeGot.forEach(item => {
              item.lastChangedDate = new Date(item.lastChangedDate);
            });
            
            // Find the latest object
            const sortedItems = dataWeGot.slice().sort((a, b) => b.lastChangedDate - a.lastChangedDate);
            const latestItem = sortedItems[0];
            const nextLatestItem = sortedItems.find(item => item.lastChangedDate < latestItem.lastChangedDate);
            const downloadURL =  await getArtifactFromBuild(filePath.includes('/Archive')?nextLatestItem.id:latestItem.id,instance, accounts);
            if (downloadURL.showMessage === true){
              return dataWeGot;
             }
             if(downloadURL){
              return await downloadUrlData(downloadURL,null,branchName,firstThreeSegments,null,instance, accounts);
             } else {
              document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
              return {showMessage:true,message:'No software Found Check the path for downloading in the Business Release, Pre-Release, or Validation Release folders '};
             }
          } else {
            document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
            return {showMessage:true,message:'No software Found Check the path for downloading in the Business Release, Pre-Release, or Validation Release folders '};
          }
      }
  } else {
    return await downloadDataForSingleFileFolder(branchName,filePath,instance, accounts);
  }
  document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
}



export const downloadUrlData = async (downloadUrl, fileName = null,branchName,filePath,multiple = null,instance, accounts) => {
  document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
  const response = await fetch(downloadUrl, {
    headers: {
      Authorization: `Bearer ${await accessToken(instance, accounts)}`,
    }
  });

  if (!response.ok) {
    document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
    return {showMessage: true,message:`There's an error downloading the data. Please refresh the page and try again.`};
  }

  const blob = await response.blob();

  try {
    // Open directory picker dialog
    

    if (fileName) {
      const directoryHandle = await window.showDirectoryPicker();
      // Use JSZip to load the zip file
      const zip = await JSZip.loadAsync(blob);

      // Iterate over all files in the zip
      for await (const [relativePath, file] of Object.entries(zip.files)) {
        if (!file.dir && relativePath.endsWith(fileName)) {  // ignore directories and non-matching files
          const content = await file.async('blob');  // or 'text', 'arraybuffer', 'uint8array', 'nodebuffer' as per your need
          const filename = relativePath.split('/').pop();
          const fileHandle = await directoryHandle.getFileHandle(filename, { create: true });
          const writableStream = await fileHandle.createWritable();
          await writableStream.write(content);
          await writableStream.close();
          return {showMessage: true,message:`Software downloaded successfully!`};
          break; // If fileName is provided, download only that file
        }
      }
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
    } else {
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      try {
        if(multiple === 'multiple'){
          return blob;
        } else {
          try{
            const directoryHandle = await window.showDirectoryPicker();
            const packageName = filePath.split('/');
            let downloadArtifactName = [
                  branchName,
                  ...packageName.filter(Boolean).map((pkg, index) => `${'_'}${pkg}`)
                ].join('');
            const fileHandle = await directoryHandle.getFileHandle(downloadArtifactName+'.zip', { create: true });
            const writableStream = await fileHandle.createWritable();
            await writableStream.write(blob);  
            await writableStream.close();
            return {showMessage: true,message:`Software downloaded successfully!`};
          } catch {
            return {showMessage: true,message:`The directory selection was canceled. Please try downloading again.`};
          }

        }
      } catch (error) {
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        return {showMessage: true,message:`There's an error downloading the data. Please refresh the page and try again.`};
      }
    }
    document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
  } catch (error) {
    if (error.name === 'AbortError') {
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return {showMessage: true,message:`The directory selection was canceled. Please try downloading again. `};
    } else {
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return {showMessage: true,message:`There's an error downloading the data. Please refresh the page and try again.`};
    }
  }
};

async function getRunIdsForPipeline(pipelineId, instance, accounts){

    const response = await fetch(
      `https://dev.azure.com/${process.env.REACT_APP_AUTH_CLIENT_ID}/${process.env.REACT_APP_PUBLIC_KEY}/_apis/pipelines/${pipelineId}/runs?api-version=6.0`,
      {
        headers: {
          Authorization: `Bearer ${await accessToken(instance, accounts)}`,
        },
      }
    );
    if(response.status !== 200){
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return {showMessage: true,message:`There's a problem getting the data. Please refresh the page and try again.`};
    }
    const result = await response.json();
    return result.value.filter(o=>o.state === 'completed' && o.result ==='succeeded').map(o=>o.id)
}


export async function getBuilds(instance, account,runIds){

  let queryParams = `api-version=6.1`;
    if(runIds){
        queryParams = `${queryParams}&buildIds=${runIds}`
    }
    const response = await fetch(
      `https://dev.azure.com/${process.env.REACT_APP_AUTH_CLIENT_ID}/${process.env.REACT_APP_PUBLIC_KEY}/_apis/build/builds?${queryParams}`,
      {
        headers: {
          Authorization: `Bearer ${await accessToken(instance, account)}`,
        },
      }
    );
    if(response.status !== 200){
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return {showMessage: true,message:`There's a problem getting the data. Please refresh the page and try again.`};
    }
    const result = await response.json()
  return result.value.filter(r=>r.parameters).map(r=>({...r,parameters:JSON.parse(r.parameters)}))
}

export async function getBuildsForPipeline(pipelineId, instance, accounts, folderPath,branchName){
  const ids = await getRunIdsForPipeline(pipelineId,instance, accounts);
  if(ids.showMessage){
   return ids;
  }
  const result = await getBuilds(instance, accounts,ids.join(","));
  if(result.showMessage){
    return result;
   }
   const dataResultFiltered = result.filter(r => {
    let item = r.parameters;
    const pattern = new RegExp(`^${folderPath}(?:/[^/]+)?(?:/.*)?$`);
    return pattern.test(item.destinationFolder) && item.artifactName.includes(branchName);
   });

  return dataResultFiltered;
}

export async function getArtifactFromBuild(buildId,instance, accounts){

  const response = await fetch(
    `https://dev.azure.com/${process.env.REACT_APP_AUTH_CLIENT_ID}/${process.env.REACT_APP_PUBLIC_KEY}/_apis/build/builds/${buildId}/artifacts?api-version=6.1`,
    {
      headers: {
        Authorization: `Bearer ${await accessToken(instance, accounts)}`,
      },
    }
  );
  if(response.status !== 200){
    document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
    return {showMessage: true,message:`There's a problem getting the data. Please refresh the page and try again.`};
  }
  const result = await response.json();
  return result.value.map(o=>o.resource.downloadUrl);
}